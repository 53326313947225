"use client";

import { trpc } from "@/providers/TrpcProvider";
import { GetRsusReturnType } from "@/server/providers/rsus";
import { GetTaxOpportunityReturnType, StrategyDetails } from "@/server/providers/strategy";
import { GetBestDaysToSellReturnType, GetDrawdownRiskReturnType, GetExposureReturnType, GetLatestStockPriceReturnType, GetSeasonalityReturnType, GetSpyComponentsReturnType, GetTickerAssignmentReturnType } from "@/server/providers/tickers";
import { TRPCClientErrorLike } from "@trpc/client";
import { createContext, useContext } from "react";
type StrategyContextType = {
  drawdownRisk: GetDrawdownRiskReturnType | undefined;
  exposure: GetExposureReturnType | undefined;
  bestDaysToSell: GetBestDaysToSellReturnType | undefined;
  seasonality: GetSeasonalityReturnType | undefined;
  tickerAssignment: GetTickerAssignmentReturnType | undefined;
  rsus: GetRsusReturnType | undefined | null;
  strategy: StrategyDetails | undefined;
  latestPrice: number | undefined;
  refetchStrategy: () => void;
  refetchRsus: () => void;
  refetchSeasonality: () => void;
  refetchBestDaysToSell: () => void;
  refetchExposure: () => void;
  refetchDrawdownRisk: () => void;
  refetchTickerAssignment: () => void;
  refetchTaxOpportunity: () => void;
  refetchSpyComponents: () => void;
  refetchLatestPrice: () => void;
  taxOpportunity: GetTaxOpportunityReturnType | undefined;
  spyComponents: GetSpyComponentsReturnType | undefined;
  seasonalityError: TRPCClientErrorLike<any> | undefined;
  bestDaysToSellError: TRPCClientErrorLike<any> | undefined;
  taxOpportunityError: TRPCClientErrorLike<any> | undefined;
  strategyError: TRPCClientErrorLike<any> | undefined;
  rsusError: TRPCClientErrorLike<any> | undefined;
  spyComponentsError: TRPCClientErrorLike<any> | undefined;
  exposureError: TRPCClientErrorLike<any> | undefined;
  drawdownRiskError: TRPCClientErrorLike<any> | undefined;
  tickerAssignmentError: TRPCClientErrorLike<any> | undefined;
};
const StrategyContext = createContext<StrategyContextType>({} as StrategyContextType);
export default function StrategyProvider({
  children,
  seasonality: defaultSeasonality,
  bestDaysToSell: defaultBestDaysToSell,
  exposure: defaultExposure,
  tickerAssignment: defaultTickerAssignment,
  drawdownRisk: defaultDrawdownRisk,
  rsus: defaultRsus,
  strategy: defaultStrategy,
  taxOpportunity: defaultTaxOpportunity,
  spyComponents: defaultSpyComponents,
  latestPrice: defaultLatestPrice
}: {
  children: React.ReactNode;
  seasonality: GetSeasonalityReturnType | undefined;
  bestDaysToSell: GetBestDaysToSellReturnType | undefined;
  exposure: GetExposureReturnType | undefined;
  tickerAssignment: GetTickerAssignmentReturnType | undefined;
  drawdownRisk: GetDrawdownRiskReturnType | undefined;
  rsus: GetRsusReturnType | undefined;
  strategy: StrategyDetails | undefined;
  taxOpportunity: GetTaxOpportunityReturnType | undefined;
  spyComponents: GetSpyComponentsReturnType | undefined;
  latestPrice: GetLatestStockPriceReturnType | undefined;
}) {
  const {
    data: tickerAssignment,
    error: tickerAssignmentError,
    refetch: refetchTickerAssignment
  } = trpc.tickers.getTickerAssignment.useQuery(undefined, {
    initialData: defaultTickerAssignment,
    enabled: !!defaultTickerAssignment
  });
  const {
    data: seasonality,
    error: seasonalityError,
    refetch: refetchSeasonality
  } = trpc.tickers.getSeasonality.useQuery({
    ticker: tickerAssignment?.ticker ?? ""
  }, {
    initialData: defaultSeasonality,
    enabled: !!defaultSeasonality
  });
  const {
    data: bestDaysToSell,
    error: bestDaysToSellError,
    refetch: refetchBestDaysToSell
  } = trpc.tickers.getBestDaysToSell.useQuery({
    ticker: tickerAssignment?.ticker ?? ""
  }, {
    initialData: defaultBestDaysToSell,
    enabled: !!defaultBestDaysToSell
  });
  const {
    data: taxOpportunity,
    error: taxOpportunityError,
    refetch: refetchTaxOpportunity
  } = trpc.strategy.getTaxOpportunity.useQuery(undefined, {
    initialData: defaultTaxOpportunity,
    enabled: !!defaultTaxOpportunity
  });
  const {
    data: spyComponents,
    error: spyComponentsError,
    refetch: refetchSpyComponents
  } = trpc.tickers.getSpyConcentration.useQuery({
    ticker: tickerAssignment?.ticker ?? ""
  }, {
    initialData: defaultSpyComponents,
    enabled: !!defaultSpyComponents
  });
  const {
    data: exposure,
    error: exposureError,
    refetch: refetchExposure
  } = trpc.tickers.getExposure.useQuery({
    ticker: tickerAssignment?.ticker ?? ""
  }, {
    initialData: defaultExposure,
    enabled: !!defaultExposure
  });
  const {
    data: drawdownRisk,
    error: drawdownRiskError,
    refetch: refetchDrawdownRisk
  } = trpc.tickers.getDrawdownRisk.useQuery({
    ticker: tickerAssignment?.ticker ?? ""
  }, {
    initialData: defaultDrawdownRisk,
    enabled: !!defaultDrawdownRisk
  });
  const {
    data: strategy,
    error: strategyError,
    refetch: refetchStrategy
  } = trpc.strategy.getStrategyDetails.useQuery(undefined, {
    initialData: defaultStrategy,
    enabled: !!defaultStrategy,
    refetchOnWindowFocus: true
  });
  const {
    data: rsus,
    error: rsusError,
    refetch: refetchRsus
  } = trpc.rsu.getRsus.useQuery(undefined, {
    initialData: defaultRsus,
    enabled: !!defaultRsus,
    refetchOnWindowFocus: true
  });
  const {
    data: latestPrice,
    error: latestPriceError,
    refetch: refetchLatestPrice
  } = trpc.tickers.getLatestStockPrice.useQuery(tickerAssignment?.ticker ? [tickerAssignment.ticker] : [], {
    initialData: defaultLatestPrice,
    enabled: !!defaultLatestPrice
  });
  return <StrategyContext.Provider value={{
    seasonality,
    bestDaysToSell,
    drawdownRisk,
    exposure,
    rsus,
    strategy,
    taxOpportunity,
    spyComponents,
    tickerAssignment: tickerAssignment ?? undefined,
    latestPrice: tickerAssignment?.ticker ? latestPrice?.[tickerAssignment.ticker] : undefined,
    refetchStrategy,
    refetchRsus,
    refetchSeasonality,
    refetchBestDaysToSell,
    refetchExposure,
    refetchDrawdownRisk,
    refetchTickerAssignment,
    refetchTaxOpportunity,
    refetchSpyComponents,
    refetchLatestPrice,
    seasonalityError: seasonalityError || undefined,
    bestDaysToSellError: bestDaysToSellError || undefined,
    taxOpportunityError: taxOpportunityError || undefined,
    strategyError: strategyError || undefined,
    rsusError: rsusError || undefined,
    spyComponentsError: spyComponentsError || undefined,
    exposureError: exposureError || undefined,
    drawdownRiskError: drawdownRiskError || undefined,
    tickerAssignmentError: tickerAssignmentError || undefined
  }} data-sentry-element="unknown" data-sentry-component="StrategyProvider" data-sentry-source-file="StrategyProvider.tsx">
      {children}
    </StrategyContext.Provider>;
}
export const useStrategyContext = () => useContext(StrategyContext);