import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useUser } from "@clerk/nextjs";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
export default function AuthWallDialog({
  action = "vote_card_clicked",
  title = "Create a free OnTrade account to vote on this prediction",
  subtitle = "Participate in voting, see how other votes, and unlock more when you sign up for OnTrade.",
  redirectUrl = "/",
  forceOpen = false,
  children
}: {
  action?: string;
  title?: string;
  subtitle?: string;
  redirectUrl?: string;
  forceOpen?: boolean;
  children: React.ReactNode;
}) {
  const {
    isLoaded,
    isSignedIn
  } = useUser();
  const posthog = usePostHog();
  const router = useRouter();
  const navigateToSignIn = () => {
    const urlSearchParams = new URLSearchParams();
    if (redirectUrl) {
      urlSearchParams.set("redirectUrl", redirectUrl);
    }
    router.push(`/sign-in?${urlSearchParams.toString()}`);
  };
  const navigateToSignUp = () => {
    router.push(`/sign-up`);
  };
  const [open, setOpen] = useState(forceOpen);
  const onOpenChange = (open: boolean) => {
    if (open) {
      posthog.capture("auth_wall_modal_opened", {
        action
      });
    } else {
      posthog.capture("auth_wall_modal_closed", {
        action
      });
    }
    setOpen(open);
  };
  if (!isLoaded || isSignedIn) {
    return children;
  }
  return <Dialog open={open} onOpenChange={onOpenChange} data-sentry-element="Dialog" data-sentry-component="AuthWallDialog" data-sentry-source-file="AuthWallDialog.tsx">
      <DialogTrigger asChild className="cursor-pointer" data-sentry-element="DialogTrigger" data-sentry-source-file="AuthWallDialog.tsx">
        {children}
      </DialogTrigger>
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="AuthWallDialog.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="AuthWallDialog.tsx">
          <DialogTitle className="text-3xl font-bold" data-sentry-element="DialogTitle" data-sentry-source-file="AuthWallDialog.tsx">{title}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-8">
          <p className="text-lg">{subtitle}</p>
          <p className="text-lg">
            It&apos;s free to join and only takes a minute.
          </p>
        </div>
        <div className="flex flex-col gap-4 items-center justify-center w-full mt-12">
          <Button className="w-full" onClick={navigateToSignUp} data-sentry-element="Button" data-sentry-source-file="AuthWallDialog.tsx">
            Sign up for OnTrade
            <ChevronRightIcon strokeWidth={2} className="w-6 h-6 ml-2" data-sentry-element="ChevronRightIcon" data-sentry-source-file="AuthWallDialog.tsx" />
          </Button>
          <div>
            <span>Already have an account? </span>
            <Link href={"/sign-in"} className="text-primary font-bold" onClick={navigateToSignIn} data-sentry-element="Link" data-sentry-source-file="AuthWallDialog.tsx">
              Sign in
            </Link>
          </div>
        </div>
      </DialogContent>
    </Dialog>;
}