import { RsuBasicRecord } from "@/db/schema";
import { RsuGrantResponse } from "@ontrade/feedback-api";
import dayjs from "dayjs";

export const getSharesCalculations = (
  grants: RsuGrantResponse[] | undefined | null,
  rsus: RsuBasicRecord | undefined | null
) => {
  const totalRsus =
    grants && grants.length > 0
      ? grants.reduce(
          (acc, grant) =>
            acc +
            (grant?.vestings?.reduce(
              (acc, vesting) => acc + (vesting?.vestingAmount ?? 0),
              0
            ) ?? 0),
          0
        )
      : rsus?.totalRsu || 0;

  const ownedRsus =
    grants && grants.length > 0
      ? grants.reduce(
          (acc, grant) =>
            acc +
            (grant?.vestings
              ?.filter((vesting) =>
                dayjs(vesting?.vestingDate).isBefore(dayjs())
              )
              .reduce((acc, vesting) => acc + (vesting?.ownedAmount ?? 0), 0) ??
              0),
          0
        )
      : rsus?.ownedRsu || 0;

  const vestedShares =
    grants && grants.length > 0
      ? grants.reduce(
          (acc, grant) =>
            acc +
            (grant?.vestings
              ?.filter((vesting) =>
                dayjs(vesting?.vestingDate).isBefore(dayjs())
              )
              .reduce(
                (acc, vesting) => acc + (vesting?.vestingAmount ?? 0),
                0
              ) ?? 0),
          0
        )
      : rsus?.vestedRsu || 0;

  return {
    totalRsus,
    ownedRsus,
    vestedShares,
  };
};
