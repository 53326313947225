"use client";

import AuthWallDialog from "@/app/(home)/_components/AuthWallDialog";
import { useStrategyContext } from "@/app/(home)/strategy/_components/StrategyProvider";
import { cn } from "@/lib/utils";
import { trpc } from "@/providers/TrpcProvider";
import { useUser } from "@clerk/nextjs";
import dayjs from "dayjs";
import { Loader2Icon } from "lucide-react";
import { useRouter } from "next/navigation";
import { useMemo } from "react";
import { Bar, Cell, LabelList, BarChart as RechartsBarChart, ResponsiveContainer } from "recharts";
import { LineChart } from "../charts/LineChart";
import { buttonVariants } from "../ui/button";
import { CardContainer, ErrorCard, InfoPoint } from "./CardComponents";
import { getSharesCalculations } from "./utils";
const seasonalityInfo: InfoPoint[] = [{
  title: "Methodology",
  description: "Graph displays cumulative daily returns starting from Jan 1st of each year (averaged over the past 15 years), and historical volatility is displayed below the graph. We calculate the average daily returns on a stock using a method that helps smooth out the price ups and downs over the year (called continuously compounded annualized rate of return or CCR). Unlike simpler methods like arithmetic returns that calculate a straightforward average, CCR takes compounding into account giving a more accurate reflection of your investment's true growth over time."
}, {
  title: "Insight",
  description: "Historical trends, seasonal patterns, and volatility can help you decide when to hold or sell your RSUs."
}, {
  title: "Significance",
  description: "The timing of your sale can translate to a significant impact in net proceeds."
}, {
  title: "Actionable Steps",
  steps: [{
    title: "Review RSU Vesting Schedule",
    description: "Understand how many RSUs you will have vested before a peak quarter to better estimate your impact."
  }, {
    title: "Monitor Q4 Market Trends",
    description: "Stay informed about current conditions that may impact stock performance."
  }, {
    title: "Leverage Volatility Insights",
    description: "Be aware of heightened volatility to optimize your selling strategy."
  }]
}];
export const SeasonalityCard = ({
  isFlipped,
  setIsFlipped
}: {
  isFlipped: boolean;
  setIsFlipped: (isFlipped: boolean) => void;
}) => {
  const {
    seasonality,
    tickerAssignment,
    seasonalityError,
    tickerAssignmentError,
    refetchSeasonality,
    refetchTickerAssignment
  } = useStrategyContext();
  if (!seasonality || !tickerAssignment) return <ErrorCard title="Seasonality" errorMessage={seasonalityError?.message || tickerAssignmentError?.message || "Something went wrong"} className="h-[536px]" refetch={() => {
    refetchSeasonality();
    refetchTickerAssignment();
  }} ticker={tickerAssignment?.ticker} />;
  const avgCumYearlyReturn = seasonality.cum_returns[seasonality.cum_returns.length - 1].r;
  let highestIndex = 0;
  for (let i = 0; i < seasonality.cum_returns.length; i++) {
    if (seasonality.cum_returns[i].r > seasonality.cum_returns[highestIndex].r) {
      highestIndex = i;
    }
  }
  const highestCumReturnDay = seasonality.cum_returns?.[highestIndex]?.d;
  const date = dayjs(dayjs().year() + highestCumReturnDay || undefined);
  const quarter = Math.floor(date.month() / 3) + 1;
  let quarterText = "first";
  if (quarter === 2) quarterText = "second";else if (quarter === 3) quarterText = "third";else if (quarter === 4) quarterText = "fourth";
  const seasonalityText = {
    title: `Stock historically peaks in Q${quarter}`,
    description: `The average annual return of ${tickerAssignment.ticker} has been 
    ${avgCumYearlyReturn.toLocaleString("en-US", {
      style: "percent",
      maximumFractionDigits: 0
    })} over the past ${seasonality.total_years_count} years with 
    the seasonal peak of ${tickerAssignment.ticker} stock occurring 
    in the ${quarterText} quarter. In the last ${seasonality.total_years_count} years, 
    the stock has peaked ${seasonality.num_years_where_peak_matched_target_quarter} 
    times in the ${quarterText} quarter.`
  };
  let minVolatility = 0;
  let maxVolatility = 0;
  if (seasonality) {
    Object.entries(seasonality.weekly_vol).forEach(([week, vol]) => {
      if (vol < minVolatility) minVolatility = vol;
      if (vol > maxVolatility) maxVolatility = vol;
    });
  }
  return <CardContainer isFlipped={isFlipped} setIsFlipped={setIsFlipped} headerTitle="Seasonality" info={seasonalityInfo} ticker={tickerAssignment?.ticker} cardValue={{
    text: `VALUE OF STRATEGY ${"+" + seasonality.strategy_value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0
    })}`
  }} className="h-[534px]" showError={Boolean(seasonalityError || tickerAssignmentError)} errorMessage={seasonalityError?.message || tickerAssignmentError?.message || "Something went wrong"} refetch={() => {
    refetchSeasonality();
    refetchTickerAssignment();
  }} headline={seasonalityText.title} data-sentry-element="CardContainer" data-sentry-component="SeasonalityCard" data-sentry-source-file="StrategyCards.tsx">
      <div className="px-4 pb-2 flex flex-col gap-2">
        <p>{seasonalityText.description}</p>
        <div>
          <LineChart className="h-28" data={seasonality.cum_returns.map(item => ({
          ...item,
          date: dayjs(dayjs().year() + "-" + item.d).format("MMM")
        }))} index="date" colors={["orange"]} categories={["r"]} showGridLines showLegend={false} showTooltip={false} valueFormatter={value => value.toLocaleString("en-US", {
          style: "percent",
          maximumFractionDigits: 0
        })} data-sentry-element="LineChart" data-sentry-source-file="StrategyCards.tsx" />
          <p className="text-xs font-semibold text-center">
            Average daily return over last {seasonality.total_years_count} years
          </p>
          <div className="h-12 pl-11">
            <ResponsiveContainer data-sentry-element="ResponsiveContainer" data-sentry-source-file="StrategyCards.tsx">
              <RechartsBarChart layout="horizontal" data={Object.entries(seasonality.weekly_vol).map(([week, vol]) => ({
              week,
              vol
            }))} data-sentry-element="RechartsBarChart" data-sentry-source-file="StrategyCards.tsx">
                <Bar dataKey="vol" data-sentry-element="Bar" data-sentry-source-file="StrategyCards.tsx">
                  {Object.entries(seasonality.weekly_vol).map(function (entry, index) {
                  return <Cell key={`cell-${index}`} fill={getSeasonalityColor({
                    max: maxVolatility,
                    min: minVolatility,
                    value: entry[1]
                  })} radius={[2, 2, 0, 0] as any} />;
                })}
                </Bar>
              </RechartsBarChart>
            </ResponsiveContainer>
          </div>
          <p className="text-xs font-semibold text-center">
            Weeks {seasonality.mostVolatileWeeks.map(w => +w + 1).join(", ")}{" "}
            tend to be most volatile.
          </p>
        </div>
      </div>
    </CardContainer>;
};
const bestDayToSellInfo: InfoPoint[] = [{
  title: "Methodology",
  description: "The plot utilizes 15 years of daily return data to display the average cumulative returns for the upcoming trading window."
}, {
  title: "Insight",
  description: "Stocks often experience increased volatility immediately after earnings announcements."
}, {
  title: "Significance",
  description: "The timing of your sale can have a significant (positive or negative) impact on net proceeds."
}, {
  title: "Actionable Steps",
  steps: [{
    title: "Determine Your Desired Cash-Out",
    description: "Determine how much money you need to make from selling your RSUs during this trading window. This will influence your risk tolerance."
  }, {
    title: "Choose When to Sell or Set a Target Price",
    description: "Decide if you want to sell at a specific time or when the stock reaches a certain price and align the risk you're willing to take with your risk tolerance."
  }]
}];
export const BestDayToSellCard = ({
  isFlipped,
  setIsFlipped
}: {
  isFlipped: boolean;
  setIsFlipped: (isFlipped: boolean) => void;
}) => {
  const {
    bestDaysToSell,
    bestDaysToSellError,
    tickerAssignment,
    refetchBestDaysToSell,
    refetchTickerAssignment
  } = useStrategyContext();
  let firstBestDayToSell = bestDaysToSell?.best_window?.[0] || undefined;
  let lastBestDayToSell = bestDaysToSell?.best_window?.[bestDaysToSell?.best_window?.length - 1] || undefined;
  if (firstBestDayToSell) {
    firstBestDayToSell = dayjs().year() + "-" + dayjs(dayjs().year() + "-" + firstBestDayToSell).format("MM-DD");
  }
  if (lastBestDayToSell) {
    lastBestDayToSell = dayjs().year() + "-" + dayjs(dayjs().year() + "-" + lastBestDayToSell).format("MM-DD");
  }
  const bestDaysToSellChartMap = new Map();
  return <CardContainer isFlipped={isFlipped} setIsFlipped={setIsFlipped} headerTitle="Best Day to Sell" info={bestDayToSellInfo} ticker={tickerAssignment?.ticker || ""} errorMessage={bestDaysToSellError?.message || "Something went wrong"} showError={Boolean(!bestDaysToSell)} refetch={() => {
    refetchBestDaysToSell();
    refetchTickerAssignment();
  }} cardValue={{
    text: `VALUE OF STRATEGY ${"+" + bestDaysToSell?.strategy_value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0
    })}`
  }} className="h-[534px]" headline={`Best days to sell: ${firstBestDayToSell ? dayjs(firstBestDayToSell).format("MMM D") : "TBD"} - ${lastBestDayToSell ? dayjs(lastBestDayToSell).format(dayjs(lastBestDayToSell).month() !== dayjs(firstBestDayToSell).month() ? "MMM D" : "D") : "TBD"}`} data-sentry-element="CardContainer" data-sentry-component="BestDayToSellCard" data-sentry-source-file="StrategyCards.tsx">
      <div className="px-4 pb-2 flex flex-col gap-2">
        <p>
          Over the past {bestDaysToSell?.years} years, on average, the highest
          stock prices have occurred between{" "}
          {[firstBestDayToSell, lastBestDayToSell].map(day => dayjs(day).format("MMM D")).join(" and ")}{" "}
          (up{" "}
          {bestDaysToSell?.maxCr?.toLocaleString("en-US", {
          style: "percent",
          maximumFractionDigits: 1
        })}{" "}
          on average) of the current or upcoming window. During the entire
          45-day window, the worst-case performance was{" "}
          {bestDaysToSell?.best_and_worst_years?.worst_return?.toLocaleString("en-US", {
          style: "percent",
          maximumFractionDigits: 1
        })}{" "}
          in {bestDaysToSell?.best_and_worst_years?.worst_year}, and the best
          was{" "}
          {bestDaysToSell?.best_and_worst_years?.best_return?.toLocaleString("en-US", {
          style: "percent",
          maximumFractionDigits: 1
        })}{" "}
          in {bestDaysToSell?.best_and_worst_years?.best_year}.
        </p>
        <div className="flex flex-col h-36 grow">
          <ResponsiveContainer className="grow w-full h-full" data-sentry-element="ResponsiveContainer" data-sentry-source-file="StrategyCards.tsx">
            <RechartsBarChart layout="horizontal" margin={{
            top: 18,
            bottom: 12,
            left: 20,
            right: 20
          }} data={bestDaysToSell?.cumulative_returns_in_window ?? []} data-sentry-element="RechartsBarChart" data-sentry-source-file="StrategyCards.tsx">
              <Bar dataKey="cr" data-sentry-element="Bar" data-sentry-source-file="StrategyCards.tsx">
                <LabelList dataKey="cr" position="top" content={(props: any) => renderOpenWindowLabel({
                x: props.x as number,
                y: props.y as number,
                width: props.width as number,
                height: props.height as number,
                value: props.value as number,
                max: bestDaysToSell?.maxCr,
                min: bestDaysToSell?.minCr,
                showedMap: bestDaysToSellChartMap
              })} data-sentry-element="LabelList" data-sentry-source-file="StrategyCards.tsx" />
                {bestDaysToSell?.cumulative_returns_in_window.map(function (entry, index) {
                return <Cell key={`cell-${index}`} fill={getColor({
                  max: bestDaysToSell?.maxCr,
                  min: bestDaysToSell?.minCr,
                  value: entry.cr
                })} radius={[2, 2, 0, 0] as any} />;
              })}
              </Bar>
            </RechartsBarChart>
          </ResponsiveContainer>
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm font-medium">
              {dayjs(dayjs().year() + "-" + bestDaysToSell?.cumulative_returns_in_window?.[0]?.d).format("MMM D")}
            </p>
            <p className="text-sm font-medium">
              {dayjs(dayjs().year() + "-" + bestDaysToSell?.cumulative_returns_in_window?.[bestDaysToSell?.cumulative_returns_in_window.length - 1]?.d).format("MMM D")}
            </p>
          </div>
          <p className="text-xs text-center font-semibold mt-1 pb-0">
            % change over Day 1 of trading window price
          </p>
        </div>
      </div>
    </CardContainer>;
};
export const BestDayToSellDirectCard = ({
  ticker
}: {
  ticker: string;
}) => {
  const router = useRouter();
  const {
    isSignedIn
  } = useUser();
  const {
    data: bestDaysToSell,
    error: apiError,
    isLoading
  } = trpc.tickers.getBestDaysToSell.useQuery({
    ticker
  }, {
    enabled: !!ticker,
    trpc: {
      context: {
        skipBatch: true
      }
    }
  });
  const error = apiError ? new Error(apiError.message || "Failed to fetch data") : null;
  let firstBestDayToSell = bestDaysToSell?.best_window?.[0] || undefined;
  let lastBestDayToSell = bestDaysToSell?.best_window?.[bestDaysToSell?.best_window?.length - 1] || undefined;
  if (firstBestDayToSell) {
    firstBestDayToSell = dayjs().year() + "-" + dayjs(dayjs().year() + "-" + firstBestDayToSell).format("MM-DD");
  }
  if (lastBestDayToSell) {
    lastBestDayToSell = dayjs().year() + "-" + dayjs(dayjs().year() + "-" + lastBestDayToSell).format("MM-DD");
  }
  const bestDaysToSellChartMap = new Map();
  return <div className="flex flex-col justify-between gap-4 w-full bg-card rounded-xl p-4 border border-border shadow-md h-full" data-sentry-component="BestDayToSellDirectCard" data-sentry-source-file="StrategyCards.tsx">
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-row items-start justify-between gap-4">
          <p className="font-semibold text-sm">Best Day to Sell</p>
        </div>

        {isLoading ? <div className="flex items-center justify-center h-full">
            <Loader2Icon className="animate-spin rounded-full h-8 w-8" />
          </div> : <div className="flex flex-col gap-2">
            <h4 className="text-3xl font-bold">
              {firstBestDayToSell ? dayjs(firstBestDayToSell).format("MMM D") : "TBD"}
              -
              {lastBestDayToSell ? dayjs(lastBestDayToSell).format(dayjs(lastBestDayToSell).month() !== dayjs(firstBestDayToSell).month() ? "MMM D" : "D") : "TBD"}
            </h4>
            <div className="flex flex-col h-48 grow">
              <p className="text-xs text-center font-semibold mt-1 pb-0">
                % change over Day 1 price of trading window
              </p>
              <ResponsiveContainer className="grow w-full h-full">
                <RechartsBarChart layout="horizontal" margin={{
              top: 18,
              bottom: 12,
              left: 20,
              right: 20
            }} data={bestDaysToSell?.cumulative_returns_in_window ?? []}>
                  <Bar dataKey="cr">
                    <LabelList dataKey="cr" position="top" content={(props: any) => renderOpenWindowLabel({
                  x: props.x as number,
                  y: props.y as number,
                  width: props.width as number,
                  height: props.height as number,
                  value: props.value as number,
                  max: bestDaysToSell?.maxCr,
                  min: bestDaysToSell?.minCr,
                  showedMap: bestDaysToSellChartMap
                })} />
                    {bestDaysToSell?.cumulative_returns_in_window.map(function (entry, index) {
                  return <Cell key={`cell-${index}`} fill={getColor({
                    max: bestDaysToSell?.maxCr,
                    min: bestDaysToSell?.minCr,
                    value: entry.cr
                  })} radius={[2, 2, 0, 0] as any} />;
                })}
                  </Bar>
                </RechartsBarChart>
              </ResponsiveContainer>
              <div className="flex flex-row items-center justify-between">
                <p className="text-xs font-medium">
                  {dayjs(dayjs().year() + "-" + bestDaysToSell?.cumulative_returns_in_window?.[0]?.d).format("MMM D")}
                </p>
                <p className="text-xs font-medium">
                  {dayjs(dayjs().year() + "-" + bestDaysToSell?.cumulative_returns_in_window?.[bestDaysToSell?.cumulative_returns_in_window.length - 1]?.d).format("MMM D")}
                </p>
              </div>
            </div>

            <p className="text-xs">
              Over the past {bestDaysToSell?.years} years, on average, the
              highest stock prices have occurred between{" "}
              {[firstBestDayToSell, lastBestDayToSell].map(day => dayjs(day).format("MMM D")).join(" and ")}{" "}
              (up{" "}
              {bestDaysToSell?.maxCr?.toLocaleString("en-US", {
            style: "percent",
            maximumFractionDigits: 1
          })}{" "}
              on average) of the current or upcoming window.
            </p>
          </div>}
      </div>

      <div className="flex flex-row items-center justify-center">
        <AuthWallDialog action="best_day_to_sell_card_clicked" title="Create a free OnTrade account to explore and plan a strategy" subtitle="OnTrade helps you pick the best day to sell or know what to expect at earnings. Add your goals and get personalized tips to maximize your wealth." redirectUrl={`/strategy?ticker=${ticker}`} data-sentry-element="AuthWallDialog" data-sentry-source-file="StrategyCards.tsx">
          <button onClick={() => {
          if (isSignedIn) {
            router.push(`/strategy?ticker=${ticker}`);
          }
        }} className={cn(buttonVariants({
          variant: "outline",
          size: "sm"
        }), "w-full ")}>
            Explore more strategy
          </button>
        </AuthWallDialog>
      </div>
    </div>;
};
const benchmarkComparisonInfo: InfoPoint[] = [{
  title: "Methodology",
  description: "Graph displays cumulative returns over the past 12 months for your company stock compared to a diversified benchmark."
}, {
  title: "Insight",
  description: "Historically, very few individual stocks have outperformed the market over the long term. In a well-known study of over 64k stocks (between 1990 and 2020), the majority of U.S. stocks underperformed one-month U.S. Treasury bills in terms of compound returns."
}, {
  title: "Significance",
  description: "This highlights the risk and potentially reduced returns of having heavy concentration in one or a few stocks."
}, {
  title: "Actionable Steps",
  steps: [{
    title: "Review Your Holdings",
    description: "Assess the percentage of your portfolio invested in your company stock."
  }, {
    title: "Rebalance into Index Funds",
    description: "Sell a portion of your RSU holdings and invest in diversified funds like SPY or QQQ."
  }, {
    title: "Understand The Benefits",
    description: "Track your diversified portfolio to confirm reduced volatility and steadier returns."
  }]
}];
export const BenchmarkComparisonCard = ({
  isFlipped,
  setIsFlipped
}: {
  isFlipped: boolean;
  setIsFlipped: (isFlipped: boolean) => void;
}) => {
  const {
    exposure,
    exposureError,
    tickerAssignment,
    refetchExposure,
    refetchTickerAssignment
  } = useStrategyContext();
  return <CardContainer isFlipped={isFlipped} setIsFlipped={setIsFlipped} headerTitle="Benchmark Comparison" info={benchmarkComparisonInfo} ticker={tickerAssignment?.ticker || ""} showError={!exposure || !tickerAssignment} errorMessage={exposureError?.message || "Something went wrong"} className="h-[520px]" refetch={() => {
    refetchExposure();
    refetchTickerAssignment();
  }} cardValue={{
    text: `VALUE OF STRATEGY: DECREASED RISK`
  }} headline={`Diversification reduces risk`} data-sentry-element="CardContainer" data-sentry-component="BenchmarkComparisonCard" data-sentry-source-file="StrategyCards.tsx">
      <div className="px-4 pb-2 flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <p>
            While {tickerAssignment?.ticker}{" "}
            {(exposure?.tickerGrowth?.[exposure?.tickerGrowth?.length - 1]?.growth || 0) > 0 ? "grew" : "shrunk"}{" "}
            {(exposure?.tickerGrowth?.[exposure?.tickerGrowth?.length - 1]?.growth || 0) > 0 ? "+" : ""}
            {exposure?.tickerGrowth?.[exposure?.tickerGrowth?.length - 1]?.growth.toLocaleString("en-US", {
            style: "percent",
            maximumFractionDigits: 2
          })}
            , a comparable diversified investment such as the S&P 500 performed{" "}
            {(exposure?.spyGrowth?.[exposure?.spyGrowth?.length - 1]?.growth || 0) > 0 ? "+" : ""}
            {exposure?.spyGrowth?.[exposure?.spyGrowth?.length - 1]?.growth.toLocaleString("en-US", {
            style: "percent",
            maximumFractionDigits: 2
          })}
            . Diversification not only reduces risk{" "}
            <span className="underline">
              but also makes it easier to achieve solid, steady growth
            </span>{" "}
            compared to relying on high returns from a single stock.
          </p>
        </div>
        <div>
          <LineChart className="h-[88px] mt-2 -ml-4" data={exposure?.tickerGrowth?.map((item, index) => ({
          date: item.date,
          value: item.growth,
          indexValue: exposure?.spyGrowth?.[index]?.growth
        })) ?? []} index="date" categories={["value", "indexValue"]} colors={["orange", "secondary"]} showGridLines showLegend={false} showXAxis={false} showTooltip={false} valueFormatter={value => value.toLocaleString("en-US", {
          style: "percent",
          maximumFractionDigits: 0
        })} data-sentry-element="LineChart" data-sentry-source-file="StrategyCards.tsx" />
          <div className="flex flex-row items-center justify-around">
            <div className="flex flex-row gap-1 items-center">
              <div className="w-2 h-2 bg-orange-500 rounded-full" />
              <span className="text-xs">{tickerAssignment?.ticker}</span>
              <span className="text-xs text-orange-500 font-bold">
                {(exposure?.tickerGrowth?.[exposure?.tickerGrowth?.length - 1]?.growth || 0) > 0 ? "+" : ""}
                {exposure?.tickerGrowth?.[exposure?.tickerGrowth?.length - 1]?.growth.toLocaleString("en-US", {
                style: "percent",
                maximumFractionDigits: 2
              })}
              </span>
            </div>
            <div className="flex flex-row gap-1 items-center">
              <div className="w-2 h-2 bg-secondary rounded-full" />
              <span className="text-xs">SPY</span>
              <span className="text-xs text-secondary font-bold">
                {(exposure?.spyGrowth?.[exposure?.spyGrowth?.length - 1]?.growth || 0) > 0 ? "+" : ""}
                {exposure?.spyGrowth?.[exposure?.spyGrowth?.length - 1]?.growth.toLocaleString("en-US", {
                style: "percent",
                maximumFractionDigits: 2
              })}
              </span>
            </div>
          </div>
          <p className="text-xs text-center font-semibold mt-1">
            Prior year returns for {tickerAssignment?.ticker} versus a
            diversified fund (SPY)
          </p>
        </div>
      </div>
    </CardContainer>;
};
const drawdownRiskInfo: InfoPoint[] = [{
  title: "Methodology",
  description: "The graph displays a drawdown comparison between your company stock and a diversified investment like the S&P 500 (SPY)."
}, {
  title: "Insight",
  description: "Understanding drawdown risk is vital – individual stocks are much more susceptible to drawdown risk than diversified portfolios or index funds."
}, {
  title: "Significance",
  description: "Managing drawdown risk is important to ensure funds will be available for emergencies and larger purchases in the future."
}, {
  title: "Actionable Steps",
  steps: [{
    title: "Evaluate Drawdown Exposure",
    description: "Assess your RSUs to determine how susceptible they are to significant value declines."
  }, {
    title: "Plan for Downturns",
    description: "Diversifying will insulate you from greater losses in the event you need to make a sale during a downturn."
  }]
}];
export const DrawdownRiskCard = ({
  isFlipped,
  setIsFlipped
}: {
  isFlipped: boolean;
  setIsFlipped: (isFlipped: boolean) => void;
}) => {
  const {
    drawdownRisk,
    drawdownRiskError,
    tickerAssignment,
    refetchDrawdownRisk,
    refetchTickerAssignment
  } = useStrategyContext();
  return <CardContainer isFlipped={isFlipped} setIsFlipped={setIsFlipped} headerTitle="Drawdown Risk" info={drawdownRiskInfo} ticker={tickerAssignment?.ticker || ""} className="h-[520px]" showError={Boolean(!drawdownRisk || !tickerAssignment || drawdownRiskError)} errorMessage={drawdownRiskError?.message || "Something went wrong"} refetch={() => {
    refetchDrawdownRisk();
    refetchTickerAssignment();
  }} cardValue={{
    text: `VALUE OF STRATEGY: DECREASED RISK`
  }} headline={`Outsized risk of a deep downside`} data-sentry-element="CardContainer" data-sentry-component="DrawdownRiskCard" data-sentry-source-file="StrategyCards.tsx">
      <div className="px-4 pb-2 flex flex-col gap-2 h-full">
        <p>
          Individual stocks like {tickerAssignment?.ticker} have generally
          demonstrated a greater downside risk during downturn periods compared
          to a diversified investment like the S&P 500 (SPY).
        </p>
        <div>
          <LineChart className="h-[152px] mt-2 pointer-events-none" data={drawdownRisk?.tickerDrawdownRisk?.drawdown?.map((item, index) => ({
          date: item.date,
          value: item.value,
          indexValue: drawdownRisk?.spyDrawdownRisk?.drawdown[index]?.value
        })) ?? []} colors={["orange", "secondary"]} index="date" categories={["value", "indexValue"]} showGridLines valueFormatter={value => value.toLocaleString("en-US", {
          style: "percent",
          maximumFractionDigits: 0
        })} showLegend={false} showTooltip={false} data-sentry-element="LineChart" data-sentry-source-file="StrategyCards.tsx" />
          <div className="flex flex-row items-center justify-around">
            <div className="flex flex-row gap-1 items-center">
              <div className="w-2 h-2 bg-orange-500 rounded-full" />
              <span className="text-xs">{tickerAssignment?.ticker}</span>
            </div>
            <div className="flex flex-row gap-1 items-center">
              <div className="w-2 h-2 bg-secondary rounded-full" />
              <span className="text-xs">SPY</span>
            </div>
          </div>
          <p className="text-xs text-center font-semibold mt-1">
            Down periods for {tickerAssignment?.ticker} usually dip much lower
            than index funds, exposing you to greater losses
          </p>
        </div>
      </div>
    </CardContainer>;
};
const exposureInfo: InfoPoint[] = [{
  title: "Methodology",
  description: "Based on the strategy inputs you provided, the concentration of your company stock has been calculated as a percentage of your total portfolio size."
}, {
  title: "Insight",
  description: "If you are over-concentrated, selling some of your shares and diversifying into index funds like the S&P 500 (SPY) or NASDAQ-100 (QQQ) can reduce your investment risk."
}, {
  title: "Significance",
  description: "Rebalancing your portfolio decreases single-stock risk and provides broader market exposure, leading to more stable and potentially higher returns."
}, {
  title: "Actionable Steps",
  steps: [{
    title: "Review Your Holdings",
    description: "Assess the percentage of your portfolio invested in your company stock."
  }, {
    title: "Rebalance into Index Funds",
    description: "Consider selling a portion of your RSUs and investing in diversified funds."
  }, {
    title: "Understand The Benefits",
    description: "Track the past volatility and returns of your company stock and compare it to the historical performances of index funds."
  }]
}];
export const ExposureCard = ({
  isFlipped,
  setIsFlipped,
  latestPrice
}: {
  isFlipped: boolean;
  setIsFlipped: (isFlipped: boolean) => void;
  latestPrice: number | undefined;
}) => {
  const {
    exposure,
    tickerAssignment,
    spyComponents,
    rsus,
    strategy,
    refetchExposure,
    refetchTickerAssignment,
    refetchRsus,
    refetchStrategy,
    refetchLatestPrice
  } = useStrategyContext();
  const {
    data: grants
  } = trpc.rsu.getRsuGrants.useQuery();
  const {
    vestedShares,
    totalRsus
  } = getSharesCalculations(grants, rsus);
  const {
    currentExposure,
    futureExposure,
    currentExposurePercentage,
    futureExposurePercentage,
    lowerOverexposureBound,
    upperOverexposuireBound,
    targetExposure,
    targetExposurePercentage
  } = useMemo(() => {
    if (exposure && tickerAssignment && rsus && strategy && latestPrice) {
      const targetExposure = 0.1;
      const currentExposure = (strategy?.rsuPercentageToTotalPortfolio ?? 0) / 100;
      const totalPortfolioValue = vestedShares * latestPrice / currentExposure;
      const unvestedShares = totalRsus - vestedShares;
      const futureTotalPortfolioValue = unvestedShares * latestPrice + totalPortfolioValue;
      const currentExposurePercentage = currentExposure * 100;
      const targetExposurePercentage = targetExposure * 100;
      const futureExposure = totalRsus * latestPrice / (futureTotalPortfolioValue || 1);
      const futureExposurePercentage = Math.round(futureExposure * 100);
      const lowerOverexposureBound = Math.floor((currentExposure - 0.1) * vestedShares);
      const upperOverexposuireBound = Math.floor((currentExposure - 0.05) * vestedShares);
      return {
        currentExposure,
        futureExposure,
        currentExposurePercentage,
        futureExposurePercentage,
        lowerOverexposureBound,
        upperOverexposuireBound,
        targetExposure,
        targetExposurePercentage
      };
    }
    return {
      currentExposure: 0,
      futureExposure: 0,
      currentExposurePercentage: 0,
      futureExposurePercentage: 0,
      lowerOverexposureBound: 0,
      upperOverexposuireBound: 0,
      targetExposure: 0,
      targetExposurePercentage: 0
    };
  }, [exposure, tickerAssignment, rsus, strategy, latestPrice, vestedShares, totalRsus]);
  return <CardContainer isFlipped={isFlipped} setIsFlipped={setIsFlipped} info={exposureInfo} ticker={tickerAssignment?.ticker || ""} headerTitle="Exposure" className="h-[520px]" cardValue={{
    text: `VALUE OF STRATEGY: DECREASED RISK`
  }} headline={`Balance your holdings`} showError={!exposure || !tickerAssignment || !rsus || !strategy || !latestPrice} errorMessage="Something went wrong" refetch={() => {
    console.log("refetching exposure");
    refetchExposure();
    refetchTickerAssignment();
    refetchRsus();
    refetchStrategy();
    refetchLatestPrice();
  }} data-sentry-element="CardContainer" data-sentry-component="ExposureCard" data-sentry-source-file="StrategyCards.tsx">
      <div className="px-4 pb-2 flex flex-col gap-2 justify-between">
        <p className="text-base">
          Your current concentration in {tickerAssignment?.ticker} is{" "}
          {(currentExposure * 100).toFixed(0)}%.{" "}
          {spyComponents && `The concentration of ${tickerAssignment?.ticker} in the S&P index is ${spyComponents?.toLocaleString("en-US", {
          style: "percent",
          maximumFractionDigits: 0
        })}.`}{" "}
          Experts recommend having no single stock holding greater than 5-10% of
          your portfolio.{" "}
          {rsus?.vestedRsu !== 0 && currentExposure > 0.05 && (currentExposure <= 0.1 ? `Given your vested holdings, you are potentially overexposed by up to ${upperOverexposuireBound?.toLocaleString("en-US", {
          maximumFractionDigits: 0
        })} shares.` : `Given your vested holdings, you are potentially overexposed between ${lowerOverexposureBound?.toLocaleString("en-US", {
          maximumFractionDigits: 0
        })} and ${upperOverexposuireBound?.toLocaleString("en-US", {
          maximumFractionDigits: 0
        })} shares.`)}
        </p>
        <div className="flex flex-row items-center justify-between mt-6">
          {[{
          label: "Target exposure",
          value: targetExposure
        }, {
          label: "Current exposure",
          value: currentExposure
        }, {
          label: "Future exposure",
          value: futureExposure
        }].sort((a, b) => a.value - b.value).map((item, index) => <ExposureLabel key={item.label} label={item.label} value={item.value} align={index === 0 ? "left" : index === 2 ? "right" : "center"} />)}
        </div>
        <div className="relative">
          <div className="w-full h-12 rounded-lg relative" style={{
          // need a gradient that goes from green to orange to red, with orange at 30%
          background: "linear-gradient(90deg, #22c55e 0%, #f97316 40%, #ef4444 60%)"
        }}>
            <div className="absolute h-12 border border-dashed border-black z-10" style={{
            left: `${targetExposurePercentage}%`
          }} />
            <div className="absolute h-12 border-2 border-primary z-10" style={{
            ...(currentExposurePercentage > 50 ? {
              right: `${100 - currentExposurePercentage}%`
            } : {
              left: `${currentExposurePercentage}%`
            })
          }} />
            <div className="absolute h-12 border border-dashed border-primary/50 z-10" style={{
            ...(futureExposurePercentage > 50 ? {
              right: `${100 - futureExposurePercentage}%`
            } : {
              left: `${futureExposurePercentage}%`
            })
          }} />
          </div>
        </div>
      </div>
    </CardContainer>;
};
const taxesInfo: InfoPoint[] = [{
  title: "Methodology",
  description: "We've calculated the potential net gain for shares held longer than 12 months. This calculation uses the stock price increase over the past year and calculates the potential 10-17% net tax savings from long-term capital gains."
}, {
  title: "Insight",
  description: "Selling vested shares held for over a year qualifies for long-term capital gains tax rates, reducing your tax liability by 10–17%, depending on your tax bracket."
}, {
  title: "Significance",
  description: "Prioritizing the sale of long-term shares can lead to substantial tax savings, maximizing the net value you retain from your investments."
}, {
  title: "Actionable Steps",
  steps: [{
    title: "Evaluate Holding Periods",
    description: "Identify which shares have been held for over a year to qualify for long-term capital gains."
  }, {
    title: "Prioritize the Sale of Long-Term Shares",
    description: "If you need to sell shares, consider selecting those that have been held for longer than 12 months to capture the tax benefit."
  }, {
    title: "Consult a Tax or Investment Expert",
    description: "Tax laws and investment strategies can be complex and vary based on individual circumstances. For personalized guidance, consider seeking advice from a qualified professional."
  }]
}];
export const TaxesCard = ({
  isFlipped,
  setIsFlipped,
  latestPrice
}: {
  isFlipped: boolean;
  setIsFlipped: (isFlipped: boolean) => void;
  latestPrice: number | undefined;
}) => {
  const {
    tickerAssignment,
    rsus,
    taxOpportunity,
    strategy,
    refetchRsus,
    refetchTickerAssignment,
    refetchTaxOpportunity,
    refetchLatestPrice
  } = useStrategyContext();
  if (!tickerAssignment || !rsus || !latestPrice || !taxOpportunity) return <ErrorCard title="Taxes" errorMessage="Something went wrong" className="h-[480px]" refetch={() => {
    refetchRsus();
    refetchTickerAssignment();
    refetchTaxOpportunity();
    refetchLatestPrice();
  }} ticker={tickerAssignment?.ticker} />;
  const longTermPercentage = strategy?.vestedRsuHeldFor12Months ?? 0;
  const minTaxValue = taxOpportunity.tax_info.savings_bottom || 0;
  const maxTaxValue = taxOpportunity.tax_info.savings_top || 0;
  return <CardContainer isFlipped={isFlipped} setIsFlipped={setIsFlipped} info={taxesInfo} ticker={tickerAssignment.ticker} headerTitle="Taxes" cardValue={{
    text: `UP TO ${maxTaxValue <= 0 ? "TBD" : "+" + maxTaxValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0
    }) + " SAVINGS"}`
  }} className="h-[490px]" headline={`Prioritize selling mature shares`} data-sentry-element="CardContainer" data-sentry-component="TaxesCard" data-sentry-source-file="StrategyCards.tsx">
      <div className="px-4 pb-2 flex flex-col gap-2">
        <p>
          Long-term capital gains have a net tax benefit between 10% to 17%
          depending on your tax bracket.
        </p>
        <p>
          Selling shares held for 12 months (or longer) after vesting can
          provide a net tax benefit if they have appreciated in value.
        </p>
        <div className="px-4">
          <div className="flex flex-row items-center justify-between text-sm">
            <p className="w-24 text-sm">Short term held shares</p>
            <p className="text-right w-24 text-sm">Long term held shares</p>
          </div>
          <div className="flex flex-row w-full">
            <div className={cn(`flex items-center justify-start px-4 font-bold rounded-l-lg text-left`, `h-10 bg-[#18A80040] z-10`)} style={{
            width: `${100 - longTermPercentage}%`
          }}>
              {(100 - longTermPercentage).toFixed(0)}%
            </div>
            <div className={cn(`flex items-center justify-end px-4 font-bold text-white rounded-r-lg text-right`, `h-10 bg-[#18A800] z-0`)} style={{
            width: `${longTermPercentage}%`
          }}>
              {longTermPercentage.toFixed(0)}%
            </div>
          </div>
          <p className="text-center text-xs font-semibold mt-2">
            Share of RSU types by capital gains status
          </p>
        </div>
      </div>
    </CardContainer>;
};
const getSeasonalityColor = ({
  max,
  min,
  value
}: {
  max: number;
  min: number;
  value: number;
}) => {
  const range = max - min;
  const percentage = (value - min) / range;
  if (percentage < 0.2) return "green";
  if (percentage < 0.4) return "#DAA520"; // dark yellow
  if (percentage < 0.6) return "#FFA500"; // orange
  if (percentage < 0.8) return "#FF8C00"; // dark orange
  if (percentage < 0.9) return "#FF4500"; // orange red
  return "red";
};
const getColor = ({
  max,
  min,
  value
}: {
  max: number | undefined;
  min: number | undefined;
  value: number;
}) => {
  if (min === undefined || max === undefined) return "green";
  const range = max - min;
  const percentage = (value - min) / range;
  if (percentage < 0.15) return "#FF0000"; // bright red
  if (percentage < 0.3) return "#FF4500"; // orange red
  if (percentage < 0.45) return "#FF6B00"; // dark orange
  if (percentage < 0.6) return "#FF8C00"; // orange
  if (percentage < 0.75) return "#FFA500"; // amber
  return "green";
};
const renderOpenWindowLabel = (props: {
  x: number;
  y: number;
  width: number;
  height: number;
  value: number;
  min: number | undefined;
  max: number | undefined;
  showedMap: Map<string, number>;
}) => {
  const {
    x,
    y,
    width,
    height,
    value,
    min,
    max,
    showedMap
  } = props;
  if (value !== min && value !== max) return null;
  const color = getColor({
    max,
    min,
    value
  });
  const showed = showedMap.get(`value-${value}`);
  if (showed !== x && showed !== undefined) return null;
  showedMap.set(`value-${value}`, x);
  return <g data-sentry-element="g" data-sentry-component="renderOpenWindowLabel" data-sentry-source-file="StrategyCards.tsx">
      <text fontSize={12} fontWeight={600} x={x - width / 2} y={y + (value > 0 ? -8 : 12)} fill={color} data-sentry-element="text" data-sentry-source-file="StrategyCards.tsx">
        {value.toLocaleString("en-US", {
        style: "percent",
        maximumFractionDigits: 0
      })}
      </text>
    </g>;
};
const ExposureLabel = ({
  label,
  value,
  align = "left"
}: {
  label: string;
  value: number;
  align?: "left" | "center" | "right";
}) => {
  const color = getColor({
    max: 1,
    min: 0,
    value: 1 - value
  });
  return <div className={cn("text-left w-12", align === "center" && "text-center", align === "right" && "text-right")} data-sentry-component="ExposureLabel" data-sentry-source-file="StrategyCards.tsx">
      <p className="text-xs">{label}</p>
      <p className="font-semibold text-xl" style={{
      color
    }}>
        {value.toLocaleString("en-US", {
        style: "percent",
        maximumFractionDigits: 0
      })}
      </p>
    </div>;
};